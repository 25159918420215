import React, { useEffect, useRef } from 'react';

const Canvas = ({ canvasId, width, height }) => {
    const canvasRef = useRef(null);
    const drawingCommandsRef = useRef([]);
    const isDrawingRef = useRef(false);
    const lastXRef = useRef(0);
    const lastYRef = useRef(0);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.lineWidth = 1.8;
        ctx.lineCap = 'round';

        const startDrawing = (e) => {
            isDrawingRef.current = true;
            [lastXRef.current, lastYRef.current] = [e.offsetX, e.offsetY];
            drawingCommandsRef.current.push({ type: 'M', x: lastXRef.current, y: lastYRef.current });
        };

        const interpolateBezierCurve = (x1, y1, x2, y2) => {
            const points = [];
            const controlPointX = (x1 + x2) / 2;
            const controlPointY = (y1 + y2) / 2;

            points.push({ x: x1, y: y1 });
            points.push({ x: controlPointX, y: controlPointY });
            points.push({ x: controlPointX, y: controlPointY });
            points.push({ x: x2, y: y2 });

            return points;
        };

        const draw = (e) => {
            if (!isDrawingRef.current) return;

            const currentX = e.offsetX;
            const currentY = e.offsetY;

            const points = interpolateBezierCurve(lastXRef.current, lastYRef.current, currentX, currentY);

            ctx.beginPath();
            for (let i = 0; i < points.length - 1; i += 3) {
                const p0 = points[i];
                const p1 = points[i + 1];
                const p2 = points[i + 2];
                const p3 = points[i + 3];

                ctx.moveTo(p0.x, p0.y);
                ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y);
            }
            ctx.stroke();

            [lastXRef.current, lastYRef.current] = [currentX, currentY];

            drawingCommandsRef.current.push({ type: 'L', x: currentX, y: currentY });
        };

        const stopDrawing = () => {
            isDrawingRef.current = false;
        };

        canvas.addEventListener('mousedown', startDrawing);
        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('mouseup', stopDrawing);
        canvas.addEventListener('mouseout', stopDrawing);

        return () => {
            canvas.removeEventListener('mousedown', startDrawing);
            canvas.removeEventListener('mousemove', draw);
            canvas.removeEventListener('mouseup', stopDrawing);
            canvas.removeEventListener('mouseout', stopDrawing);
        };
    }, []);

    const resetCanvas = () => {
        drawingCommandsRef.current = [];
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    const saveSVG = () => {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svg.setAttribute('width', canvasRef.current.width);
        svg.setAttribute('height', canvasRef.current.height);

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

        const commands = drawingCommandsRef.current.map(cmd => `${cmd.type}${cmd.x},${cmd.y}`).join(' ');

        path.setAttributeNS(null, 'd', commands);
        path.setAttribute('stroke', 'black');
        path.setAttribute('fill', 'none');
        path.setAttribute('stroke-width', '1.800');
        path.setAttribute('stroke-linecap', 'round');

        svg.appendChild(path);

        const serializer = new XMLSerializer();
        const svgString = serializer.serializeToString(svg);

        injectSVG(svgString);
    };

    const injectSVG = (svgString) => {
        resetCanvas();

        const image = new Image();
        image.src = 'data:image/svg+xml;base64,' + btoa(svgString);

        image.onload = () => {
            const ctx = canvasRef.current.getContext('2d');
            ctx.drawImage(image, 0, 0);
        };
    };

    return (
        <div>
            <canvas style={{ border: '1px solid black' }} id={canvasId} ref={canvasRef} width={width} height={height} />
            <button onClick={resetCanvas}>Reset</button>
            <button onClick={saveSVG}>Save</button>
        </div>
    );
};

const App = () => {
    return (
        <div>
            <Canvas canvasId="SignatureCanvas" width={400} height={300} />
            <Canvas canvasId="SignatureCanvas2" width={400} height={300} />
        </div>
    );
};

export default App;
