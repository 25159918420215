import React from 'react'
import { Fct, Enum } from '../Global'
import { PostAnswers, PostTeacherSignature } from '../../api/Survey'
import { Canvas } from '../Controls/Canvas'
import { RichText } from './RichText'
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert';
import * as Icon from 'react-bootstrap-icons';

class Question extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange.bind(this);
        this.handleCanvasSave.bind(this);
        this.state = { q: props.value };
    }

    handleChange = (u, q, e, onBlur) => {

        var userId = u != null ? u.Id : null;

        var answer = { UserId: userId, StringValue: null, QuestionId: q.Id, FormId: q.FormId, DoubleValue: null, CourseId: this.props.courseId, BoolValue: null, AttendeeId: this.props.attendeeId, AnswerId: null };

        // Filtrage sur l'utilisateur
        var answers = q.UserAnswers;

        // Réponse unique
        if (answers.length > 0) answer = answers[0];

        if (q.TypeId === Enum.FormType.Text) {
            answer.StringValue = e;
            q.UserAnswers = [answer];
            if (onBlur) {
                PostAnswers([answer]);
                q.ResponseIsNotValid = !Fct.Validate(q.Validation, answer.StringValue);
            }
        }
        else if (q.TypeId === Enum.FormType.Numeric) {
            answer.DoubleValue = Fct.NeGarderQueLesChiffresEtPoint((e || '').trim().replace(",", "."));
            q.UserAnswers = [answer];
            if (onBlur) {
                PostAnswers([answer]);
                q.ResponseIsNotValid = !Fct.Validate(q.Validation, answer.DoubleValue);
            }
        }
        else if (q.TypeId === Enum.FormType.Smiley || q.TypeId === Enum.FormType.QCU) {
            answer.AnswerId = e;
            answer.BoolValue = true;

            for (var i = 0; i < q.Answers.length; i++) {
                if (q.Answers[i].Id === e) {
                    q.Answers[i].UserAnswers = [answer];
                }
                else {
                    q.Answers[i].UserAnswers = [];
                }
            }
            PostAnswers([answer]);
        }
        else if (q.TypeId === Enum.FormType.QCM) {

            answer.AnswerId = e; // [FORM_ANSWERS].AnswerId

            for (var j = 0; j < q.Answers.length; j++) {
                if (q.Answers[j].Id === e) {
                    if (q.Answers[j].UserAnswers.length > 0 /* La personne a déjà répondu */) {
                        q.Answers[j].UserAnswers[0].BoolValue = !q.Answers[j].UserAnswers[0].BoolValue;
                        answer.BoolValue = q.Answers[j].UserAnswers[0].BoolValue;
                    }
                    else {
                        answer.BoolValue = true;
                        q.Answers[j].UserAnswers.push(answer);
                    }
                }
            }

            // Pour les questions à plusieurs choix possible toutes les réponses sont postées.
            PostAnswers(q.Answers.flatMap(a => a.UserAnswers));
        }
        else if (q.TypeId === Enum.FormType.TrueFalse) {
            answer.BoolValue = e === 'true';
            q.UserAnswers = [answer];
            PostAnswers([answer]);
        }
        else if (q.TypeId === Enum.FormType.YesNo) {
            answer.BoolValue = e === 'true';
            q.UserAnswers = [answer];
            PostAnswers([answer]);
        }

        this.setState({ q: q });
    };

    handleCanvasSave = (svg, parameters, callBackSuccess, callBackSError) => {

        var svgIsNotVoid = svg && svg.indexOf('d=""') === -1;

        if (parameters && parameters.length === 2) {

            var u = parameters[0];
            var q = parameters[1];

            if (u && q && q.TypeId === Enum.FormType.Text && q.TeacherControlSignature) {

                if (svgIsNotVoid) {
                    q.TeacherControlSignature.Signature = svg;

                    PostTeacherSignature(this.props.courseId, q.FormId, this.props.attendeeId, q.TeacherControlSignature.Id, svg, callBackSuccess, callBackSError);

                    this.setState({ q: q });
                }


            }
            else if (q && q.TypeId === Enum.FormType.Signature) {

                if (svgIsNotVoid) {
                    var userId = u != null ? u.Id : null;

                    var answer = { UserId: userId, StringValue: null, QuestionId: q.Id, FormId: q.FormId, DoubleValue: null, CourseId: this.props.courseId, BoolValue: null, AttendeeId: this.props.attendeeId, AnswerId: null };

                    // Filtrage sur l'utilisateur
                    var answers = q.UserAnswers;

                    // Réponse unique
                    if (answers.length > 0) answer = answers[0];

                    answer.StringValue = svg;

                    q.UserAnswers = [answer];

                    PostAnswers([answer], callBackSuccess, callBackSError);

                    this.setState({ q: q });
                }
            }

            if (q.Validation) {

                q.ResponseIsNotValid = !svgIsNotVoid;

                this.setState({ q: q });
            }
        }
    };

    render() {

        var q = this.state.q;
        var u = this.props.user;
        var trainers = this.props.course.TrainersDto || [];
        var formateur = trainers.filter(x => x.RoleValue === "Formateur");
        var tuteur = trainers.filter(x => x.RoleValue === "Tuteur");
        var comite = trainers.filter(x => x.RoleValue === "ComitePedagogique");
        var formateurUserId = formateur.length === 1 ? formateur[0].UserId : null;
        var tuteurUserId = tuteur.length === 1 ? tuteur[0].UserId : null;
        var comiteUserId = comite.length === 1 ? comite[0].UserId : null;
        var isComite = comiteUserId > 0 && u?.Id === comiteUserId;

        var getAnswer = function (u, qu, p /*property*/, v, userId) {

            var answers = qu.UserAnswers;

            if (userId != null) {
                if (userId === 0) {
                    answers = Fct.ArrayFilterByZeroOrNull(qu.SurveyAnswers, "UserId");
                }
                else {
                    answers = qu.SurveyAnswers.filter(x => x.UserId === userId);
                }
            }

            if (p === "BoolValue") {
                return (answers.length === 1 ? (v ? answers[0].BoolValue : !answers[0].BoolValue) : null);
            }
            else {
                return answers.length === 1 ? answers[0][p] : "";
            }
        };

        var getAnswerAnswer = function (u, ans, userId) {

            var answers = ans.UserAnswers;

            if (userId != null) {
                if (userId === 0) {
                    answers = Fct.ArrayFilterByZeroOrNull(ans.SurveyAnswers, "UserId");
                }
                else {
                    answers = ans.SurveyAnswers.filter(x => x.UserId === userId);
                }
            }

            answers = Fct.ArrayFilterBy(answers, "BoolValue", true);

            return answers.length > 0;
        }

        return (
            <React.Fragment>
                {q.TypeId === Enum.FormType.Titre ?
                    <RichText cssClass="qRichText" value={q.RichText}></RichText>
                    :
                    <RichText cssClass="qRichText" value={q.RichText}></RichText>
                }
                {q.MinScore &&
                    <div className="text-secondary">Note mini : {q.MinScore}</div>
                }
                {(q.ParentId > 0 && u?.Id > 0 /* uniquement pour le livret de compétence (à paramêtrer)  */) ? (
                    <React.Fragment>
                        {q.TypeId === Enum.FormType.Text &&
                            <div className="f-label border p-2">
                                {getAnswer(u, q, "StringValue", null, 0)} &nbsp;
                            </div>
                        }
                        {q.TypeId === Enum.FormType.Signature &&
                            <div className="f-control m-3">
                                <Canvas parameters={[u, q]} onSave={this.handleCanvasSave} svg={getAnswer(u, q, "StringValue")} canvasId={'SignatureCanvas-question-' + q.Id} width={300} height={200} />
                            </div>
                        }
                        {q.TypeId === Enum.FormType.Numeric &&
                            <React.Fragment>
                                {u?.Id > 0 ? (
                                    <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                                        <i className="text-center">
                                            ( Eval. Apprenant : {Fct.DefaultValueIfEmpty(getAnswer(u, q, "DoubleValue", null, 0), '-')} )
                                            {(tuteurUserId > 0 && isComite) &&
                                                <div>(Eval. Tuteur : {Fct.DefaultValueIfEmpty(getAnswer(u, q, "DoubleValue", null, tuteurUserId), '-')} )</div>
                                            }
                                            {(formateurUserId > 0 && isComite) &&
                                                <div>(Eval. Formateur : {Fct.DefaultValueIfEmpty(getAnswer(u, q, "DoubleValue", null, formateurUserId), '-')} )</div>
                                            }
                                        </i>
                                        <div>
                                            <Form.Control className="text-center w-100px" value={getAnswer(u, q, "DoubleValue")} onBlur={e => this.handleChange(u, q, e.target.value, true)} onChange={e => this.handleChange(u, q, e.target.value)} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="f-control justify-content-center align-items-center">
                                        <Form.Control value={getAnswer(u, q, "DoubleValue")} onBlur={e => this.handleChange(u, q, e.target.value, true)} onChange={e => this.handleChange(u, q, e.target.value)} />
                                    </div>
                                )}
                            </React.Fragment>
                        }
                        {q.TypeId === Enum.FormType.TrueFalse &&
                            <div className="f-label">
                                {[true, false].map((v) =>
                                    <React.Fragment key={v}>
                                        {getAnswer(u, q, "BoolValue", v, 0) &&
                                            <React.Fragment>
                                                {v ? 'Oui' : 'Non'}
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )}
                            </div>
                        }
                        {q.TypeId === Enum.FormType.YesNo &&
                            <div className="f-label">
                                {[true, false].map((v) =>
                                    <React.Fragment key={v}>
                                        {getAnswer(u, q, "BoolValue", v, 0) &&
                                            <React.Fragment>
                                                {v ? 'Vrai' : 'Faux'}
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )}
                            </div>
                        }
                        {q.TypeId === Enum.FormType.QCU &&
                            <div className="f-label">
                                {q.Answers.map((a) =>
                                    <React.Fragment key={a.Id}>
                                        {getAnswerAnswer(u, a, 0) &&
                                            <React.Fragment>
                                                {a.Label}
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )}
                            </div>
                        }
                        {q.TypeId === Enum.FormType.QCM &&
                            <React.Fragment>
                                {q.Answers.map((a) =>
                                    <React.Fragment key={a.Id}>
                                        {getAnswerAnswer(u, a, 0) ? (
                                            <div className="d-flex align-items-center">
                                                <Icon.CheckSquare className="text-success me-1" size={18} /> <span>{a.Label}</span>
                                            </div>) : (
                                            <div className="d-flex">
                                                <Icon.Square className="text-secondary me-1" size={18} /> <span>{a.Label}</span>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        }
                        {q.TypeId === Enum.FormType.Smiley &&
                            <div className="f-label">
                                {q.Answers.map((a) =>
                                    <React.Fragment key={a.Id}>
                                        {getAnswerAnswer(u, a, 0) &&
                                            <React.Fragment>
                                                {a.Label}
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )}
                            </div>
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {q.TypeId === Enum.FormType.Text && !q.TeacherControlSignature &&
                            <div className="f-control m-3">
                                {q.RichText?.Intitule === 'Commentaires du tuteur en entreprise' &&
                                    <Form.Control as="textarea" value={getAnswer(u, q, "StringValue")} onBlur={e => this.handleChange(u, q, e.target.value, true)} onChange={e => this.handleChange(u, q, e.target.value)} rows={3} />
                                }
                                {q.RichText?.Intitule !== 'Commentaires du tuteur en entreprise' && q.RichText?.Visible ?
                                    <Form.Control style={{ height: '100px' }} as="textarea" value={getAnswer(u, q, "StringValue")} onBlur={e => this.handleChange(u, q, e.target.value, true)} onChange={e => this.handleChange(u, q, e.target.value)} rows={3} />
                                    :
                                    <FloatingLabel className="w-100" label={q.RichText?.Intitule + ' :'}>
                                        <Form.Control style={{ height: '100px' }} placeholder={q.RichText?.Intitule} as="textarea" value={getAnswer(u, q, "StringValue")} onBlur={e => this.handleChange(u, q, e.target.value, true)} onChange={e => this.handleChange(u, q, e.target.value)} rows={3} />
                                    </FloatingLabel>
                                }
                            </div>
                        }
                        {q.TypeId === Enum.FormType.Text && q.TeacherControlSignature &&
                            <div className="f-control m-3">
                                <Canvas parameters={[u, q]} onSave={this.handleCanvasSave} svg={q.TeacherControlSignature.Signature || ''} canvasId={'SignatureCanvas-' + q.TeacherControlSignature.Id} width={300} height={200} />
                            </div>
                        }
                        {q.TypeId === Enum.FormType.Signature &&
                            <div className="f-control m-3">
                                <Canvas parameters={[u, q]} onSave={this.handleCanvasSave} svg={getAnswer(u, q, "StringValue")} canvasId={'SignatureCanvas-question-' + q.Id} width={300} height={200} />
                            </div>
                        }
                        {q.TypeId === Enum.FormType.Numeric &&
                            <React.Fragment>
                                {u?.Id > 0 ? (
                                    <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                                        {(this.props.formCategoryId || 0) !== 3 &&
                                            <i className="text-center">
                                                ( Eval. Apprenant : {Fct.DefaultValueIfEmpty(getAnswer(u, q, "DoubleValue", null, 0), '-')} )
                                                {(tuteurUserId > 0 && isComite) &&
                                                    <div>(Eval. Tuteur : {Fct.DefaultValueIfEmpty(getAnswer(u, q, "DoubleValue", null, tuteurUserId), '-')} )</div>
                                                }
                                                {(formateurUserId > 0 && isComite) &&
                                                    <div>(Eval. Formateur : {Fct.DefaultValueIfEmpty(getAnswer(u, q, "DoubleValue", null, formateurUserId), '-')} )</div>
                                                }
                                            </i>
                                        }
                                        <div>
                                            <Form.Control className="text-center w-100px" value={getAnswer(u, q, "DoubleValue")} onBlur={e => this.handleChange(u, q, e.target.value, true)} onChange={e => this.handleChange(u, q, e.target.value)} />
                                        </div>
                                        {(q.BaremeMax || 0) > 0 &&
                                            <i className="text-center">&nbsp;/&nbsp;{q.BaremeMax}</i>
                                        }
                                    </div>
                                ) : (
                                    <div className="f-control justify-content-center align-items-center">
                                        <Form.Control value={getAnswer(u, q, "DoubleValue")} onBlur={e => this.handleChange(u, q, e.target.value, true)} onChange={e => this.handleChange(u, q, e.target.value)} />
                                        {(q.BaremeMax || 0) > 0 &&
                                            <i className="text-center">&nbsp;/&nbsp;{q.BaremeMax}</i>
                                        }
                                    </div>
                                )}
                            </React.Fragment>
                        }
                        {q.TypeId === Enum.FormType.TrueFalse &&
                            <div className="f-control">
                                {[true, false].map((v) =>
                                    <Form.Check
                                        inline
                                        checked={getAnswer(u, q, "BoolValue", v)}
                                        label={v ? 'Vrai' : 'Faux'}
                                        name={'radio-' + q.Id}
                                        type="radio"
                                        key={'radio_' + q.Id + "_" + (v ? '1' : '0')}
                                        id={'radio_' + q.Id + "_" + (v ? '1' : '0')}
                                        value={v}
                                        onChange={e => this.handleChange(u, q, e.target.value)}
                                    />
                                )}
                            </div>
                        }
                        {q.TypeId === Enum.FormType.YesNo &&
                            <div className="f-control">
                                {[true, false].map((v) =>
                                    <Form.Check
                                        inline
                                        checked={getAnswer(u, q, "BoolValue", v)}
                                        label={v ? 'Oui' : 'Non'}
                                        name={'radio-' + q.Id}
                                        type="radio"
                                        key={'radio_' + q.Id + "_" + (v ? '1' : '0')}
                                        id={'radio_' + q.Id + "_" + (v ? '1' : '0')}
                                        value={v}
                                        onChange={e => this.handleChange(u, q, e.target.value)}
                                    />
                                )}
                            </div>
                        }
                        {q.TypeId === Enum.FormType.QCU &&
                            <div className="f-control flex-column gap-4 mt-4 mb-4">
                                {q.Answers.map((a) =>
                                    <Form.Check
                                        inline
                                        checked={getAnswerAnswer(u, a)}
                                        label={a.Label}
                                        name={'radio-' + q.Id}
                                        type="radio"
                                        key={a.Id}
                                        id={'radio-' + a.Id}
                                        onChange={() => this.handleChange(u, q, a.Id)}
                                    />
                                )}
                            </div>
                        }
                        {q.TypeId === Enum.FormType.QCM &&
                            <div className="f-control">
                                {q.Answers.map((a) =>
                                    <Form.Check
                                        checked={getAnswerAnswer(u, a)}
                                        label={a.Label}
                                        name={'checkbox-' + q.Id}
                                        type="checkbox"
                                        key={a.Id}
                                        id={'checkbox-' + a.Id}
                                        onChange={() => this.handleChange(u, q, a.Id)}
                                    />
                                )}
                            </div>
                        }
                        {q.TypeId === Enum.FormType.Smiley &&
                            <div className="f-control">
                                {q.Answers.map((a) =>
                                    <Form.Check
                                        inline
                                        checked={getAnswerAnswer(u, a)}
                                        label={a.Label}
                                        name={'radio-' + q.Id}
                                        type="radio"
                                        key={a.Id}
                                        id={'radio-' + a.Id}
                                        onChange={() => this.handleChange(u, q, a.Id)}
                                    />
                                )}
                            </div>
                        }
                    </React.Fragment>
                )}

                {(q.Validation && q.ResponseIsNotValid) &&
                    <Alert className="f-size-12px p-2" variant="warning">{q.Validation.CustomErrorMessage}</Alert>
                }
            </React.Fragment>
        )
    }
}

export { Question }