import React from 'react'
import { useParams } from 'react-router-dom';
import { Fct, Enum } from './Global'
import { GetSurvey } from '../api/Survey'
import { Blocks } from './Controls/Blocks'
import { Question } from './Controls/Question'

import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';

var CryptoJS = require("crypto-js");

/*
http://localhost:3000/survey/a36ea06d-bcaf-4552-b4cf-211800bc3898/64-57904-0-d2d28e22ba3c1912398cfac3f87b56eec358f458
http://localhost:3000/survey/a36ea06d-bcaf-4552-b4cf-211800bc3898/64-57904-353-5335175b8e9607c899e19f105022b19569dd47d9
 */

// Point d'entrée :
// Affichage Message d'erreur ou sinon Survey
function Survey() {

    const params = useParams();
    const courseguid = params.courseguid;
    const crypteddata = params.crypteddata;

    const { isLoading, error, survey } = GetSurvey(courseguid, crypteddata);

    if (error) {
        return <div>Erreur : {error.message} </div>;
    } else if (isLoading) {
        return <div>Chargement...</div>;
    } else if (survey === null) {
        return <div>Questionnaire inconnu.</div>;
    } else {
        return (<>
            {survey.Message && <div>{survey.Message}</div>}
            {!survey.Message &&
                <SurveyView survey={survey}></SurveyView>
            }
        </>);
    }
}

// Vue Survey en Boostrap
class SurveyView extends React.Component {

    constructor(props) {
        super(props);

        this.getActivBlockRank.bind(this);
        const activeBlockRank = this.getActivBlockRank('decrypt');

        var less950 = window.matchMedia("(max-width: 950px)").matches;
        this.state = { isSideBarToggleOn: (less950 ? false : true), survey: props.survey, activeBlockRank };
        this.sideBarToggleClick = this.sideBarToggleClick.bind(this);
        this.blockHandler.bind(this);
    }

    sideBarToggleClick() {
        this.setState({ isSideBarToggleOn: !this.state.isSideBarToggleOn });
    }

    getActivBlockRank(action, newRank) {

        const secretPass = "TYuyreIophG785HG";

        var getData = function (p) {
            return {
                c: p.survey.Course.Id,
                a: p.survey.Attendee.Id,
                f: p.survey.Form.Id,
                u: p.survey.User?.Id
            };
        };

        var data = getData(this.props);
        var stringified = JSON.stringify(data);

        if (action === "encrypt") {
            data.r = newRank;
            const cryptedRank = CryptoJS.AES.encrypt(JSON.stringify(data), secretPass).toString();
            localStorage.setItem(stringified, cryptedRank);
        }
        else if (action === "decrypt") {
            try {
                const stored = localStorage.getItem(stringified) || '';

                if (stored !== '') {
                    const bytes = CryptoJS.AES.decrypt(stored, secretPass);
                    const rank = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); // JSON.parse: unexpected end of data at line 1 column 1 of the JSON data

                    //console.log(stored);

                    if (rank != null) {
                        return rank.r;
                    }
                }
            }
            catch (error) {
                console.log(error);
            }

            return 1;
        }
    }

    blockHandler = (newRank) => {
        this.getActivBlockRank("encrypt", newRank)
        this.setState({ activeBlockRank: newRank });
    }

    render() {

        const survey = this.state.survey;
        const noBlock = survey.Form.Blocks.filter(x => (x.PageDeRemerciement || false) === false).length === 1;

        var findTrainer = Fct.ArrayFilterBy((survey.Course.TrainersDto || []), "UserId", survey.User?.Id || 0);
        var role = findTrainer.length === 1 ? findTrainer[0].RoleValue : "";
        role = role === "ComitePedagogique" ? "Comité Pédagogique " : role;
        role = role === "DirecteurDeProjet" ? "Directeur de projet " : role;

        var sideBarClass = "";
        if (!this.state.isSideBarToggleOn) sideBarClass = "survey-sidebar-hidden";
        if (noBlock) sideBarClass += " no-block";
        sideBarClass = sideBarClass.trim();

        return (
            <div id="SurveyWrapper" className={sideBarClass}>
                <div id="SurveySideBar" className={noBlock ? "d-none" : ""} >
                    <Stack className="m-3" gap={3}>
                        <h1>{survey.Course.Title}</h1>
                        <Blocks componentType="ListGroup" blockHandler={this.blockHandler} activeBlockRank={this.state.activeBlockRank} blocks={survey.Form.Blocks}></Blocks>
                    </Stack>
                </div>

                <div id="SurveyContener" >
                    <Navbar variant="primary" className={noBlock ? "d-none" : ""}>
                        <Button variant="primary" className="ms-2" onClick={this.sideBarToggleClick} >&#9776;</Button>
                        <div className="flex-grow-1"></div>
                        <Navbar.Text className="me-3 text-white">
                            {survey.User ? (
                                <div>
                                    <div className="f-size-13px text-black">{survey.AttendeeLabel} : <span>{survey.Attendee.FirstName} {survey.Attendee.LastName}</span></div>
                                    <div className="f-size-14px; font-bold">
                                        {role &&
                                            <span>{role} : </span>
                                        }
                                        {
                                            (survey.Form.CategoryId === 3) &&
                                            <span>&Eacute;valuation du jury</span>
                                        }
                                        {
                                            (survey.Form.CategoryId !== 3) &&
                                            <span>&Eacute;valuation de {survey.User.FirstName} {survey.User.LastName}</span>
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div>
                                        Auto-&Eacute;valuation de <span>{survey.Attendee.FirstName} {survey.Attendee.LastName}</span>
                                </div>
                            )}
                        </Navbar.Text>
                    </Navbar>

                    {noBlock &&
                        <div className="bg-primary text-white p-3">
                            {survey.AttendeeLabel} : <span>{survey.Attendee.FirstName} {survey.Attendee.LastName}</span>
                        </div>
                    }

                    <div className="wrapper-form-question">
                        <Blocks className={noBlock ? "d-none" : "wrapper-pagination"} componentType="Pagination" blockHandler={this.blockHandler} activeBlockRank={this.state.activeBlockRank} blocks={survey.Form.Blocks}></Blocks>

                        {survey.Form.Blocks.map((s) => /*ITERATION BLOCKS*/
                            <React.Fragment key={s.Rank}>
                                {(s.Rank === this.state.activeBlockRank) &&
                                    <Form className="question" key={s.Rank}>
                                        {s.Label &&
                                            <label className="block-label">{s.Label}</label>
                                        }
                                        {s.Questions.map((q) => /*ITERATION QUESTIONS*/
                                            <div className={"question question-" + Enum.FormTypeToText(q.TypeId) + (q.SubQuestions.length > 0 ? ' have-childs' : '')} key={q.Rank}>
                                                <Form.Group className="parent-question">
                                                    <Question formCategoryId={survey.Form.CategoryId} user={survey.User} value={q} courseId={survey.Course.Id} course={survey.Course} attendeeId={survey.Attendee.Id} />
                                                </Form.Group>

                                                {q.SubQuestions.length > 0 &&
                                                    <div className="childs-question">
                                                        {q.SubQuestions.map((sq) => /*ITERATION SOUS-QUESTIONS*/
                                                            <div className="child-question" key={sq.Id}>
                                                                <Form.Group>
                                                                    <Question formCategoryId={survey.Form.CategoryId} user={survey.User} value={sq} courseId={survey.Course.Id} course={survey.Course} attendeeId={survey.Attendee.Id} />
                                                                </Form.Group>
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        {s.Rank < survey.Form.Blocks.length &&
                                            <Blocks componentType="Suivant" blockHandler={this.blockHandler} activeBlockRank={this.state.activeBlockRank} blocks={survey.Form.Blocks}></Blocks>
                                        }
                                        {s.Rank === survey.Form.Blocks.length &&
                                            <Blocks componentType="Precedent" blockHandler={this.blockHandler} activeBlockRank={this.state.activeBlockRank} blocks={survey.Form.Blocks}></Blocks>
                                        }
                                    </Form>
                                }
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Survey