import React from 'react'
import { useParams } from 'react-router-dom';
import { GetEditSurvey } from '../api/Survey'
import { Blocks } from './Controls/Blocks'
import { Question } from './Controls/Question'

import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Navbar from 'react-bootstrap/Navbar';

/*
    http://localhost:3000/editsurvey/0/a/b
 */

// Point d'entrée :
// Affichage Message d'erreur ou sinon EditSurvey
function EditSurvey() {

    const params = useParams();
    const formId = params.formid;
    const userGuid = params.userguid;
    const cryptedData = params.crypteddata;

    const { isLoading, error, editSurvey } = GetEditSurvey(formId, userGuid, cryptedData);

    if (error) {
        return <div>Erreur : {error.message} </div>;
    } else if (isLoading) {
        return <div>Chargement...</div>;
    } else if (editSurvey === null) {
        return <div>Donnée non valides.</div>;
    } else {
        return (<>
            {editSurvey.Message && <div>{editSurvey.Message}</div>}
            {!editSurvey.Message &&
                <EditSurveyView editSurvey={editSurvey}></EditSurveyView>
            }
        </>);
    }
}

// TODO : continuer de dev ici
class EditSurveyView extends React.Component {

    constructor(props) {
        super(props);

        //this.getActiv.bind(this);
        //const activeBlock = this.getActiv('decrypt');

        var less950 = window.matchMedia("(max-width: 950px)").matches;
        this.state = { isSideBarToggleOn: (less950 ? false : true), survey: props.editSurvey /*, activeBlock*/ };
        this.sideBarToggleClick = this.sideBarToggleClick.bind(this);
        this.blockHandler.bind(this);
    }

    sideBarToggleClick() {
        this.setState({ isSideBarToggleOn: !this.state.isSideBarToggleOn });
    }

    blockHandler = (newRank) => {
        //this.getActiv("encrypt", newRank)
        this.setState({ activeBlock: newRank });
    }

    render() {
        const survey = this.state.survey;

        var sideBarClass = (!this.state.isSideBarToggleOn) ? "survey-sidebar-hidden" : "";

        return (
            <div id="SurveyWrapper" className={sideBarClass}>
                <div id="SurveySideBar" className="" >
                    <Stack className="m-3" gap={3}>
                        <h1>#TITLE#</h1>
                    </Stack>
                </div>

                <div id="SurveyContener" >
                    <Navbar variant="primary" className="">
                        <Button variant="primary" className="ms-2" onClick={this.sideBarToggleClick} >&#9776;</Button>
                        <div className="flex-grow-1"></div>
                        <Navbar.Text className="me-3 text-white">
                            #USER#
                        </Navbar.Text>
                    </Navbar>

                    <div className="bg-primary text-white p-3 d-none">
                        #survey.AttendeeLabel# : <span>#survey.Attendee.FirstName# #survey.Attendee.LastName#</span>
                    </div>

                    <div className="wrapper-form-question">
                        <Blocks className="wrapper-pagination" componentType="Pagination" blocks={survey.Form.Blocks} ></Blocks>


                    </div>
                </div>
            </div>
        );
    }
}

export default EditSurvey