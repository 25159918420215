import { createSlice } from '@reduxjs/toolkit'

export const providerSlice = createSlice({
    name: 'providers',
    initialState: {
        list: [],
    },
    reducers: {
        toggleProvider: (state, action) => {
            var arr = state.list;

            const index = arr.findIndex(o => { return o.MixId === action.payload.MixId; });

            if (index === -1) {
                arr.push(action.payload);
            } else {
                arr.splice(index, 1);
            }

            state.list = arr;
        }
    },
})

// Action creators are generated for each case reducer function
export const { toggleProvider } = providerSlice.actions

export default providerSlice.reducer