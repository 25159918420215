import $ from "jquery"

const Url = {
    PreprodDomain: "http://localhost:8011",
    ProdDomain: "https://admin.mypresence.fr",
    GetSurvey: "/api/survey",
    GetEditSurvey: "/api/editsurvey",
    PostAnswers: "/api/postAnswers",
    PostTeacherSignature: "/api/postTeacherSignature"
};

const Fct = {
    IsLocal: function () { return (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"); },
    ArraySelectDistinctProperty: function (arr, property) {
        const unik = [];
        arr.map(x => {
            if (unik.indexOf(x[property]) === -1) {
                unik.push(x[property]);
            }
        });
        return unik;
    },
    ArrayFilterBy: function (arr, property, value) {
        const filter = (v, arr) => arr.filter(x => x[property] === v);
        return filter(value, arr);
    },
    ArrayFilterByZeroOrNull: function (arr, property) {
        const filter = (arr) => arr.filter(x => (x[property] || 0) === 0);
        return filter(arr);
    },
    TruncateString: function (s, _length, after) {
        var text = s || '';
        if (after === null) after = "...";
        if (text.length <= _length || text.length === 0) return text;
        return $.trim(text).substring(0, _length) + after;
    },
    StringIsInteger: function (s) {
        return /^[+-]?(0|[1-9]\d*)$/.test((s || ''));
    },
    ConvertStringToFloat: function (s) {
        var st = $.trim((s || "").replace(",", "."));

        var pf = parseFloat(st);

        if (!isNaN(pf)) return pf;

        return parseFloat(0);
    },
    NeGarderQueLesChiffresEtPoint: function(s) {
        return (s || "").replace(/[^\d.]/g, "");
    },
    DefaultValueIfEmpty: function (v, s) {
        if ((v + '').trim() === '') {
            return s;
        } else {
            return v;
        }
    },
    StringIsDate: function (chaine) {
        // Expression r�guli�re pour valider les formats "jj/mm/yyyy", "jj-mm-yyyy", "jj/mm/yy" et "jj-mm-yy"
        var regex = /^(0[1-9]|[12][0-9]|3[01])[/-](0[1-9]|1[0-2])[/-](\d{4}|\d{2})$/;

        // V�rifie si la cha�ne correspond � l'un des formats de date
        if (!regex.test(chaine)) {
            return false; // La cha�ne n'est pas au bon format
        }

        // Extrait le jour, le mois et l'ann�e de la cha�ne
        var parties = chaine.split(/[/-]/);
        var jour = parseInt(parties[0], 10);
        var mois = parseInt(parties[1], 10) - 1; // Les mois commencent � partir de 0 dans l'objet Date
        var annee = parseInt(parties[2], 10);

        // V�rifie si l'ann�e est � deux chiffres et ajuste si n�cessaire
        if (annee < 100) {
            if (annee > 50) {
                annee += 1900; // Pour les ann�es de 51 � 99, ajoute 1900
            } else {
                annee += 2000; // Pour les ann�es de 00 � 50, ajoute 2000
            }
        }

        // Cr�e un nouvel objet Date et v�rifie si la date est valide
        var date = new Date(annee, mois, jour);

        // V�rifie si la date extraite est �gale � la date cr��e et si elle est valide
        return (date.getDate() === jour && date.getMonth() === mois && date.getFullYear() === annee);
    },

    Validate: (v, value) => {

        if (v) {
            if (v.Required && (value || '').toString().trim() === '') {
                return false;
            }

            if (v.Function) {
                if (v.Function === 'IsDate') {
                    return Fct.StringIsDate((value || '').trim());
                }
            }
        }

        return true;
    }

}

const Enum = {
    FormType: {
        Text: 1,
        Numeric: 2,
        TrueFalse: 3,
        YesNo: 4,
        QCU: 5,
        QCM: 6,
        Smiley: 7,
        Titre: 8,
        Signature: 9
    },
    FormTypeToText: function (integer) {
        switch (integer) {
            case this.FormType.Text: return "text";
            case this.FormType.Numeric: return "numeric";
            case this.FormType.TrueFalse: return "truefalse";
            case this.FormType.YesNo: return "yesno";
            case this.FormType.QCU: return "qcu";
            case this.FormType.QCM: return "qcm";
            case this.FormType.Smiley: return "smiley";
            case this.FormType.Titre: return "titre";
            case this.FormType.Signature: return "signature";
            default: return "";
        }
    },
}

export { Url, Fct , Enum }

/*
String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};
String.prototype.padZero = function (length) {
    var s = this;
    while (s.length < length) {
        s = '0' + s;
    }
    return s;
};
String.prototype.tryParseInt = function (defaultValue) {
    var retValue = defaultValue;
    if (this !== null) {
        var trimed = $.trim(this);
        if (trimed.length > 0) {
            if (trimed.isInteger()) {
                retValue = parseInt(trimed, 10);
            }
        }
    }
    return retValue;
};
String.prototype.isPositiveInteger = function () {
    return /^\+?(0|[1-9]\d*)$/.test(this);
};
String.prototype.isFrenchDate = function () {
    return /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(this || '');
};
String.prototype.ParseInts = function () {
    var ret = [];
    var a = (this || '').split(/,| |;|\|/);
    for (var i = 0; i < a.length; i++) {
        if (a[i].isInteger()) {
            ret.push(parseInt(a[i], 10));
        }
    }
    return ret;
};
String.prototype.ConvertToBool = function () {

    var st = (this || "").toLowerCase();

    if (st === "oui"
        || st === "yes"
        || st === "o"
        || st === "on"
        || st === "y"
        || st === "true"
        || st === "t"
    ) return true;

    if (st.tryParseInt(0) > 0) return true;

    return false;
};

String.prototype.isEmail = function () {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String((this || '')).toLowerCase());
};
String.prototype.escapeHtml = function () {
    return $("<div>").text((this || '').normalize('NFD').replace(/[\u0300-\u036f]/g, "")).html();
};
String.prototype.htmlEncode = function () {
    //create a in-memory div, set it's inner text(which jQuery automatically encodes)
    //then grab the encoded contents back out.  The div never exists on the page.
    return $('<div/>').text(this || '').html();
};
String.prototype.htmlDecode = function () {
    return $('<div/>').html(this || '').text();
};
String.prototype.RemoveDoubleSpace = function () {
    return (this || '').replace(/\s\s+/g, ' ');
};
String.prototype.isNullOrWhitespace = function () {
    if (typeof this === 'undefined' || this == null) return true;
    return this.replace(/\s/g, '').length < 1;
};

Number.prototype.getFolderName = function () {
    return (this - (this % 500)) + "-" + (this - (this % 500) + 499);
};

if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (searchElement, fromIndex) {
        var k;
        if (this == null) {
            throw new TypeError('"this" vaut null ou n est pas d�fini');
        }
        var O = Object(this);
        var len = O.length >>> 0;
        if (len === 0) {
            return -1;
        }
        var n = +fromIndex || 0;
        if (Math.abs(n) === Infinity) {
            n = 0;
        }
        if (n >= len) {
            return -1;
        }
        k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
        while (k < len) {
            if (k in O && O[k] === searchElement) {
                return k;
            }
            k++;
        }
        return -1;
    };
}
if (!Array.prototype.filter) {
    Array.prototype.filter = function (func, thisArg) {
        'use strict';
        if (!(typeof func === 'function' && this))
            throw new TypeError();

        var len = this.length >>> 0,
            res = new Array(len), // preallocate array
            t = this, c = 0, i = -1;
        if (thisArg === undefined) {
            while (++i !== len) {
                // checks to see if the key was set
                if (i in this) {
                    if (func(t[i], i, t)) {
                        res[c++] = t[i];
                    }
                }
            }
        }
        else {
            while (++i !== len) {
                // checks to see if the key was set
                if (i in this) {
                    if (func.call(thisArg, t[i], i, t)) {
                        res[c++] = t[i];
                    }
                }
            }
        }

        res.length = c; // shrink down array to proper size
        return res;
    };
}
*/
