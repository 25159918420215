import React, { Component } from 'react'
import { Routes, Route } from "react-router-dom";

import './css/custom.min.css';

import Home from './components/Home'
import Survey from './components/Survey'
import EditSurvey from './components/EditSurvey'
import TestCanvasSignature from './components/TestCanvasSignature'
import SvgDrawing from './components/TestSvgSignature'
import LivretDeCompetenceFormativesIntro from './components/BlocksClient/LivretDeCompetenceFormativesIntro'

class App extends Component {
    render() {
        return (
            <>
                <Routes>
                    <Route path="*" element={<Home />} />
                    <Route path="/LivretDeCompetenceFormativesIntro" exact element={<LivretDeCompetenceFormativesIntro /> }/>
                    <Route path="/survey/*" exact element={<Survey />} />
                    <Route path="/survey/:courseguid/:crypteddata" exact element={<Survey />} />
                    <Route path="/editsurvey/:formid/:userguid/:crypteddata" exact element={<EditSurvey />} />
                    <Route path="/test" exact element={<TestCanvasSignature />} />
                    <Route path="/test2" exact element={<SvgDrawing />} />
                </Routes>
            </>
        )
    }
}

export default App;
