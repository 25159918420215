import React, { useState } from 'react';
import { ReactComponent as ReactLogo } from '../images/logo-mypresence.svg';

class Home extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="HomeWrapper">
                <ReactLogo />
            </div>
        );
    }
}

export default Home