import React from 'react'

class RichText extends React.Component {
    render() {

        const r = this.props.value;

        var isJustif = (r.Intitule || '').indexOf("(Justification)") > -1;
        var className = isJustif ? 'justif' : '';

        className = (className + ' ' + (this.props.cssClass || '')).trim();

        return (
            <React.Fragment>
                {r.Visible &&
                    <React.Fragment>
                        {r.IsHtml &&
                            <div className={className} dangerouslySetInnerHTML={{ __html: r.Value }}></div>
                        }
                        {!r.IsHtml &&
                            <div className={className}>
                                {r.Intitule}
                            </div>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export { RichText }