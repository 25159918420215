import React, { useRef, useState } from 'react';

const SvgDrawing = () => {
    const [isDrawing, setIsDrawing] = useState(false);
    const [pathData, setPathData] = useState('');
    const lastX = useRef(0);
    const lastY = useRef(0);
    const svgRef = useRef(null);

    const startDrawing = (event) => {
        event.preventDefault(); // Prevent scrolling on touch devices
        setIsDrawing(true);
        const { clientX, clientY } = event.touches ? event.touches[0] : event;
        lastX.current = clientX - svgRef.current.getBoundingClientRect().left;
        lastY.current = clientY - svgRef.current.getBoundingClientRect().top;
        setPathData(`M ${lastX.current} ${lastY.current} `);
    };

    const continueDrawing = (event) => {
        event.preventDefault(); // Prevent scrolling on touch devices
        if (isDrawing) {
            const { clientX, clientY } = event.touches ? event.touches[0] : event;
            const currentX = clientX - svgRef.current.getBoundingClientRect().left;
            const currentY = clientY - svgRef.current.getBoundingClientRect().top;

            const controlPointX = (currentX + lastX.current) / 2;
            const controlPointY = (currentY + lastY.current) / 2;

            setPathData((prevPathData) => `${prevPathData}Q ${lastX.current} ${lastY.current} ${controlPointX} ${controlPointY} `);

            // Update lastX and lastY for the next drawing segment
            lastX.current = currentX;
            lastY.current = currentY;
        }
    };

    const stopDrawing = () => {
        setIsDrawing(false);
    };

    return (
        <svg
            ref={svgRef}
            width="400"
            height="300"
            xmlns="http://www.w3.org/2000/svg"
            style={{ border: '1px solid #ccc' }}
            onMouseDown={startDrawing}
            onMouseMove={continueDrawing}
            onMouseUp={stopDrawing}
            onMouseLeave={stopDrawing}
            onTouchStart={startDrawing}
            onTouchMove={continueDrawing}
            onTouchEnd={stopDrawing}
        >
            <path d={pathData} stroke="black" fill="none" />
        </svg>
    );
};

export default SvgDrawing;
