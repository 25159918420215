import { useEffect, useState } from 'react'
import axios from "axios";
import { Url, Fct } from '../components/Global'

function GetLastRank(survey) {

    let lastRank = survey.Form.Blocks.reduce((a, b) => {
        return (a.Rank > b.Rank) ? a : b;
    }, survey.Form.Blocks[0]);

    if (lastRank != null) {
        return lastRank.Rank;
    }
    
    return 1;
}

function FormatSurvey(survey) {

    if (survey.FormCategories && survey.Form && survey.Form.Blocks && survey.Course) {

        /*Nous vous remercions d'avoir rempli notre formulaire.*/

        var performanceId = survey.FormCategories.filter(x => x.k === "Performance");

        if (performanceId.length === 1 && performanceId[0].v === survey.Form.CategoryId) {

            if (survey.Attendee.Teachers && survey.Attendee.Teachers.length > 0) {

                var jurys = survey.Attendee.Teachers.filter(x => x.RoleId === 8);

                if (jurys.length > 0) {

                    var b = {
                        Id: -1,
                        FormId: survey.Form.Id,
                        Label: 'Signature des membres du jury',
                        Rank: GetLastRank(survey) +1,
                        Questions: [],
                        MinScore: null,
                        ParentId: null,
                        IsValid: null,
                    };

                    var questionRank = 0;

                    for (var i = 0; i < jurys.length; i++) {
                        var jury = jurys[i];

                        questionRank = (i + 1);

                        b.Questions.push({
                            BlockId: -1,
                            FormId: survey.Form.Id,
                            Id: -(i + 1),
                            Label: (jury.FirstName || '') + ' ' + (jury.LastName || ''),
                            Rank: questionRank,
                            ThemeId: 1,
                            Theme: 1,
                            TypeId: 1,
                            Type: 1,
                            IsForRating: false,
                            GoodAnswer: null,
                            MinScore: null,
                            BaremeMax: null,
                            ParentId: null,
                            UserAnswers: [],
                            AttendeeAnswers: [],
                            SurveyAnswers: [],
                            Answers: [],
                            SubQuestions: [],
                            RichText: {
                                Intitule: (jury.FirstName || '') + ' ' + (jury.LastName || ''),
                                Value: (jury.FirstName || '') + ' ' + (jury.LastName || ''),
                                IsHtml: false,
                                Visible: true,
                            },
                            TeacherControlSignature: jury
                        });
                    }

                    if (survey.ExportLink) {

                        var domain = Fct.IsLocal() ? Url.PreprodDomain : Url.ProdDomain;

                        b.Questions.push({
                            BlockId: -1,
                            FormId: survey.Form.Id,
                            Id: 0,
                            Label: survey.ExportLink,
                            Rank: (questionRank + 1),
                            ThemeId: 1,
                            Theme: 1,
                            TypeId: 8,
                            Type: 8,
                            IsForRating: false,
                            GoodAnswer: null,
                            MinScore: null,
                            BaremeMax: null,
                            ParentId: null,
                            UserAnswers: [],
                            AttendeeAnswers: [],
                            SurveyAnswers: [],
                            Answers: [],
                            SubQuestions: [],
                            RichText: {
                                Intitule: survey.ExportLink,
                                Value: '<a class="btn btn-primary mb-4 text-white" href="' + domain + survey.ExportLink + '" target="_blank">Télécharger le procès verbal.</a>',
                                IsHtml: true,
                                Visible: true,
                            }
                        });
                    }

                    survey.Form.Blocks.push(b);
                }
            }
        }

        // page de remerciement

        let lastRank = GetLastRank(survey);

        var end_b = {
            Id: -1,
            FormId: survey.Form.Id,
            Label: "Nous vous remercions d'avoir rempli notre formulaire.",
            Rank: lastRank + 1,
            Questions: [],
            MinScore: null,
            IsValid: null,
            TotalScore: null,
            ParentId: null,
            PageDeRemerciement: true
        };

        survey.Form.Blocks.push(end_b);
    }

    return survey;
}

function GetSurvey(courseGuid, cryptedData) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [survey, setSurvey] = useState(null);

    useEffect(() => {
        setSurvey(null);
    }, []);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        let cancel;

        setIsLoading(true);
        setError(false);

        var domain = Fct.IsLocal() ? Url.PreprodDomain : Url.ProdDomain;
        var url = domain + Url.GetSurvey;

        axios
            .post(url, { courseGuid, cryptedData }, {
                cancelToken: new CancelToken((c) => (cancel = c))
            })
            .then((res) => {
                setSurvey(FormatSurvey(res.data));
                setIsLoading(false);
            })
            .catch((err) => {
                if (axios.isCancel(err)) return;
                setError(err);
            });

        return () => cancel();
    }, []);

    return { isLoading, error, survey };
}

function GetEditSurvey(formId, userGuid, cryptedData) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [editSurvey, setEditSurvey] = useState(null);

    useEffect(() => {
        setEditSurvey(null);
    }, []);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        let cancel;

        setIsLoading(true);
        setError(false);

        var domain = Fct.IsLocal() ? Url.PreprodDomain : Url.ProdDomain;
        var url = domain + Url.GetEditSurvey;

        axios
            .post(url, { formId, userGuid, cryptedData }, {
                cancelToken: new CancelToken((c) => (cancel = c))
            })
            .then((res) => {
                setEditSurvey(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (axios.isCancel(err)) return;
                setError(err);
            });

        return () => cancel();
    }, []);

    return { isLoading, error, editSurvey };
}

function PostAnswers(answers, callBackSuccess, callBackSError) {
    var domain = Fct.IsLocal() ? Url.PreprodDomain : Url.ProdDomain;
    var url = domain + Url.PostAnswers;

    axios
        .post(url, { answers })
        .then((res) => {
            if (res.data) {
                if (callBackSuccess) callBackSuccess();
            } else {
                if (callBackSError) callBackSError();
            }
        });
}

function PostTeacherSignature(courseId, formId, attendeeId, teacherId, signature, callBackSuccess, callBackSError) {

    var domain = Fct.IsLocal() ? Url.PreprodDomain : Url.ProdDomain;
    var url = domain + Url.PostTeacherSignature;

    axios
        .post(url, { courseId, formId, attendeeId, teacherId, signature })
        .then((res) => {
            if (res.data) {
                if (callBackSuccess) callBackSuccess();
            } else {
                if (callBackSError) callBackSError();
            }
        });

}

export { GetSurvey, GetEditSurvey, PostAnswers, PostTeacherSignature, GetLastRank }