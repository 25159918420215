import React from 'react'
import { /*Url,*/ Fct, Enum } from '../Global'

import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

class Blocks extends React.Component {

    constructor(props) {
        super(props);
        this.state = { blocks: props.blocks };
        this.handleChange.bind(this);
    }

    handleChange = (currentRank, newRank) => {

        var currentBlocks = this.state.blocks.filter(x => x.Rank === currentRank);

        if (currentBlocks && currentBlocks.length === 1) {

            var currentBlock = currentBlocks[0];

            if (currentBlock.Questions) {
                for (var i = 0; i < currentBlock.Questions.length; i++) {

                    var q = currentBlock.Questions[i];

                    if (q.Validation) {

                        if (q.TypeId === Enum.FormType.Text || q.TypeId === Enum.FormType.Signature) {

                            var v = q.UserAnswers.length === 1 ? q.UserAnswers[0].StringValue : "";

                            var responseIsNotValid = !Fct.Validate(q.Validation, v);

                            if (responseIsNotValid) {
                                alert(q.RichText.Intitule + ' : ' + q.Validation.CustomErrorMessage);
                                return;
                            }

                        }

                        // faire les autres types
                    }
                }
            }
        }

        newRank = newRank < 1 ? 1 : newRank;
        this.props.blockHandler(newRank);
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <div className={this.props.className}>
                {this.props.componentType === "ListGroup" &&
                    <ListGroup variant="flush">
                        {this.state.blocks.filter(x => (x.PageDeRemerciement || false) === false).map((s) =>
                            <ListGroup.Item action onClick={() => this.handleChange(this.props.activeBlockRank, s.Rank)} active={s.Rank === this.props.activeBlockRank} className="cursor-pointer" key={s.Rank} variant="dark">
                                <span>{s.Label}</span>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                }
                {this.props.componentType === "Pagination" &&
                    <Pagination size="sm">
                        {this.state.blocks.filter(x => (x.PageDeRemerciement || false) === false).map((s) =>
                            <Pagination.Item onClick={() => this.handleChange(this.props.activeBlockRank, s.Rank)} key={s.Rank} active={s.Rank === this.props.activeBlockRank}>
                                {s.Rank}
                            </Pagination.Item>
                        )}
                    </Pagination>
                }
                {this.props.componentType === "Suivant" &&
                    <Form.Group className="d-flex justify-content-center">
                        <Button onClick={() => this.handleChange(this.props.activeBlockRank, (this.props.activeBlockRank + 1))} variant="primary" className="m-3 text-white"> SUIVANT</Button>
                    </Form.Group>
                }
                {this.props.componentType === "Precedent" &&
                    <Form.Group className="d-flex justify-content-center">
                        <Button onClick={() => this.handleChange(this.props.activeBlockRank, (this.props.activeBlockRank - 1))} variant="primary" className="m-3 text-white"> PRÉCÉDENT</Button>
                    </Form.Group>
                }
            </div>
        )
    }
}

export { Blocks }