import React, { useEffect, useRef, useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import * as Icon from 'react-bootstrap-icons';

const Canvas = ({ canvasId, width, height, svg, onSave, parameters }) => {
    const [isFixed, setIsFixed] = useState(false);
    const canvasRef = useRef(null);
    const drawingCommandsRef = useRef([]);
    const isDrawingRef = useRef(false);
    const lastXRef = useRef(0);
    const lastYRef = useRef(0);
    const alertRef = useRef(null);
    let ctx;

    useEffect(() => {
        const alert = alertRef.current;
        const canvas = canvasRef.current;

        ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.lineWidth = 1.8;
        ctx.lineCap = 'round';

        if(svg && (svg || '') != '') injectSVG(svg);

        const startDrawing = (e) => {
            isDrawingRef.current = true;
            [lastXRef.current, lastYRef.current] = [e.offsetX, e.offsetY];
            drawingCommandsRef.current.push({ type: 'M', x: lastXRef.current, y: lastYRef.current });
        };

        const interpolateBezierCurve = (x1, y1, x2, y2) => {
            const points = [];
            const controlPointX = (x1 + x2) / 2;
            const controlPointY = (y1 + y2) / 2;

            points.push({ x: x1, y: y1 });
            points.push({ x: controlPointX, y: controlPointY });
            points.push({ x: controlPointX, y: controlPointY });
            points.push({ x: x2, y: y2 });

            return points;
        };

        const draw = (e) => {
            if (!isDrawingRef.current) return;

            const currentX = e.offsetX;
            const currentY = e.offsetY;

            const points = interpolateBezierCurve(lastXRef.current, lastYRef.current, currentX, currentY);

            ctx.beginPath();
            for (let i = 0; i < points.length - 1; i += 3) {
                const p0 = points[i];
                const p1 = points[i + 1];
                const p2 = points[i + 2];
                const p3 = points[i + 3];

                ctx.moveTo(p0.x, p0.y);
                ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y);
            }
            ctx.stroke();

            [lastXRef.current, lastYRef.current] = [currentX, currentY];

            drawingCommandsRef.current.push({ type: 'L', x: currentX, y: currentY });
        };

        const stopDrawing = () => {
            isDrawingRef.current = false;
        };

        /*
        const handleTouchStart = (event) => {
            const { offsetX, offsetY } = event.targetTouches[0];
            ctx.beginPath();
            ctx.moveTo(offsetX, offsetY);
        };

        const handleTouchMove = (event) => {
            event.preventDefault();
            const { offsetX, offsetY } = event.targetTouches[0];
            ctx.lineTo(offsetX, offsetY);
            ctx.stroke();
        };
        */

        //canvas.addEventListener('touchstart', handleTouchStart);
        //canvas.addEventListener('touchmove', handleTouchMove);

        canvas.addEventListener('mousedown', startDrawing);
        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('mouseup', stopDrawing);
        canvas.addEventListener('mouseout', stopDrawing);

        return () => {
            //canvas.removeEventListener('touchstart', handleTouchStart);
            //canvas.removeEventListener('touchmove', handleTouchMove);

            canvas.removeEventListener('mousedown', startDrawing);
            canvas.removeEventListener('mousemove', draw);
            canvas.removeEventListener('mouseup', stopDrawing);
            canvas.removeEventListener('mouseout', stopDrawing);
        };
    }, []);



    const resetCanvas = () => {
        drawingCommandsRef.current = [];
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    const saveSVG = () => {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svg.setAttribute('width', canvasRef.current.width);
        svg.setAttribute('height', canvasRef.current.height);

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

        const commands = drawingCommandsRef.current.map(cmd => `${cmd.type}${cmd.x},${cmd.y}`).join(' ');

        path.setAttributeNS(null, 'd', commands);
        path.setAttribute('stroke', 'black');
        path.setAttribute('fill', 'none');
        path.setAttribute('stroke-width', '1.800');
        path.setAttribute('stroke-linecap', 'round');

        svg.appendChild(path);

        const serializer = new XMLSerializer();
        const svgString = serializer.serializeToString(svg);

        var callBackSuccess = function () {
            alertRef.current.innerText = ("La signature a été enregistrée.");
            alertRef.current.className = "fade alert alert-primary show";
        };

        var callBackSError = function () {
            alertRef.current.innerText = ("Une erreur est survenue.");
            alertRef.current.className = "fade alert alert-primary show";
        };

        onSave(svgString, parameters, callBackSuccess, callBackSError);
    };

    const injectSVG = (svgString) => {
        resetCanvas();

        const image = new Image();
        image.src = 'data:image/svg+xml;base64,' + btoa(svgString);

        image.onload = () => {
            const ctx = canvasRef.current.getContext('2d');
            ctx.drawImage(image, 0, 0);
        };
    };

    /*
    const signClick = () => {
        setIsFixed(!isFixed);
    };*/

    const divStyle = isFixed ? {
        overflow: 'hidden',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        backgroundColor: '#FFFFFF', // Example background color
    } : {
        position: 'static'
    };

    return (
        <div style={divStyle} >
            <div className="text-center">
                <canvas
                    style={{ border: '1px solid gray', touchAction: 'none' }}
                    id={canvasId}
                    ref={canvasRef}
                    width={width}
                    height={height}
                />
                <Alert className="d-none" ref={alertRef}></Alert>
                <Stack direction="horizontal" gap={0}>
                    <Button variant="primary" className="m-3 text-white" onClick={resetCanvas}>
                        <Icon.Trash className="text-success text-white me-1" />
                        <span>Effacer</span>
                    </Button>
                    <div className="ms-auto" />
                    <Button variant="primary" className="m-3 text-white" onClick={saveSVG}>
                        <Icon.Check className="text-success text-white me-1" />
                        <span>Enregistrer</span>
                    </Button>
                </Stack>
            </div>
        </div>
    );
};

export { Canvas }