import React from 'react';
import * as Icon from 'react-bootstrap-icons';

class LivretDeCompetenceFormativesIntro extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        //const test = "<b>test</b>";
        //<div dangerouslySetInnerHTML={{ __html: test }}></div>

        return (
            <div className="d-flex flex-column p-3">
                <h1 className="text-primary text-center">LA GRILLE D’EVALUATION<br/>DES COMPETENCES DU CANDIDAT</h1>
                <div className="p-3">Ce livret de compétences présente de manière détaillée la liste des compétences qui devra être attestée en fin de formation. La grille est à remplir par le
                    candidat, son tuteur d’entreprise et les formateurs, l’objectif étant de démontrer que le candidat a acquis 60% des compétences en fin de formation. La
                    grille est donc <b>obligatoire</b> pour l’obtention du titre. Chaque candidat doit la présenter au certificateur avant le jury de certification.</div>
                <h3 className="text-primary text-decoration-underline">Le processus</h3>
                <div className="p-3">
                    <div className="d-flex flex-column gap-1 text-center">
                        <div><div className="d-inline-block bg-primary text-white rounded p-2">Le <b>candidat</b> complète sa grille tout au long de la formation</div></div>
                        <div><Icon.ArrowDownShort className="text-primary" size={40} /></div>
                        <div><div className="d-inline-block opacity-95 bg-primary text-white rounded p-2">Le candidat transmet sa grille aux <b>évaluateurs</b></div></div>
                        <div><Icon.ArrowDownShort className="text-primary" size={40} /></div>
                        <div><div className="d-inline-block opacity-90 bg-primary text-white rounded p-2">Les <b>évaluateurs</b> retournent la grille complétée</div></div>
                        <div><Icon.ArrowDownShort className="text-primary" size={40} /></div>
                        <div><div className="d-inline-block opacity-85 bg-primary text-white rounded p-2">Le <b>candidat</b> remet sa grille à l'établissement</div></div>
                        <div><Icon.ArrowDownShort className="text-primary" size={40} /></div>
                        <div><div className="d-inline-block opacity-80 bg-primary text-white rounded p-2"><b>L'établissement</b> apporte son évaluation finale (commission d'évaluation)</div></div>
                        <div><Icon.ArrowDownShort className="text-primary" size={40} /></div>
                        <div><div className="d-inline-block opacity-75 bg-primary text-white rounded p-2"><b>L'établissement</b> remet la grille au <b>jury</b> du grand oral et au <b>certificateur</b></div></div>
                    </div>
                </div>
                <h3 className="mt-3 text-primary text-decoration-underline">Méthodologie pour le remplissage</h3>
                <div className="p-3">
                    <ul>
                        <li>Le <b>candidat s’auto évalue</b> de 1 à 5 sur les blocs de compétences 1 à 3 et sur le dernier bloc de compétences transversales.</li>
                        <li>Il <b>se justifie</b> se basant sur des <span className="text-decoration-underline">activités réalisées</span> pendant la formation (travaux de compétences, projets, examens) ou des <span className="text-decoration-underline">missions déjà effectuées</span> en
                            entreprise (cette année ou précédemment), mais également lors <span className="text-decoration-underline">d’activités non professionnelles</span> (encadrement d’une équipe sportive, coaching
                            théâtral...) qui lui ont apporté des compétences utilisables dans son domaine = case à cocher et expérience à compléter.</li>
                        <li>Le <b>tuteur en entreprise et les formateurs</b> (selon type de preuve) <b>évaluent le candidat de 1 à 5.</b></li>
                    </ul>
                </div>
                <h3 className="mt-3 text-primary text-decoration-underline">L’évaluation des compétences</h3>
                <div className="p-3">
                    <ul>
                        <li><b>1</b> (un peu de connaissance mais ne maîtrise pas – Connaissances purement théoriques)</li>
                        <li><b>2</b> (des connaissances, maîtrise sans autonomie)</li>
                        <li><b>3</b> (de bonnes connaissances et compétences, maîtrise avec autonomie)</li>
                        <li><b>4</b> (de parfaites connaissances et compétences, maîtrise experte)</li>
                        <li><b>5</b> (peut transmettre connaissances et compétences, former son remplaçant)</li>
                    </ul>
                </div>
                <h3 className="mt-3 text-primary text-decoration-underline">Les acronymes utilisés pour les «&nbsp;éléments de preuves&nbsp;»</h3>
                <div>Chaque évaluation en formation, permet aux candidats de travailler et ainsi de démontrer des compétences :</div>
                <div className="p-3">
                    <ul>
                        <li><b>RA</b> : rapport d’activités</li>
                        <li><b>EN</b> : examen national</li>
                        <li><b>DP</b> : dossier projet</li>
                        <li><b>CC</b> : contrôle continu, partiel, étude de cas, challenge, oraux</li>
                    </ul>
                </div>
                <div className="text-primary font-weight-bold">Le livret doit accompagner le candidat tout au long de l’année de formation. Vous devez compléter les grilles de compétences au fur et à mesure des
                    mises en pratique que ce soit en entreprise ou en centre de formation.</div>
                <div className="border border-primary p-2 mt-3 text-primary"><b>Les 4 blocs de compétences (bloc 1 à 3 + 4ème bloc selon la spécialité) ci-dessous doivent être validés pour obtenir la certification.</b></div>
            </div>
        );
    }
}

export default LivretDeCompetenceFormativesIntro